@import "./skeleton";

body {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::-webkit-scrollbar {
  width: 4px; }

::-webkit-scrollbar-track {
  background: whitesmoke; }

::-webkit-scrollbar-thumb {
  background: darken(whitesmoke, 20%); }

::-webkit-scrollbar-thumb:hover {
  background: #0294ff; }
