$black: #3F3942;
$grey: #747C8E;
$primary: #0294ff;

.App {
  height: 100vh;
  overflow: hidden;
  .navbar {
    padding: 1rem 3rem;
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    .path, .filter {
      padding: 1rem 0; }
    .path {
      display: flex;
      flex: 1;
      .item {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        color: $black;
        transition: all 200ms;
        &:not(:last-child) {
          cursor: pointer;
          &:after {
            content: "\0002f";
            margin-left: 10px;
            color: #b5b5b5; }
          &:hover {
            color: $primary; } }
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 15rem; } } }
    .filter {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      select, input {
        margin: 0;
        max-width: 20rem;
        min-width: 0;
        flex: 1;
        border: 0;
        background: whitesmoke;
        &:not(:first-child) {
          margin-left: 1.5rem; } } }
    &:before {
      content: "";
      display: block;
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      animation-name: loading;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transition: all 500ms;
      opacity: 0; } }
  .list {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
      border-radius: 3px;
      transition: all 300ms;
      cursor: pointer;
      width: 10rem;
      .photo {
        height: 6.4rem;
        width: 6.4rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #cfcfcf;
        border-radius: 100%;
        margin-bottom: 0.5rem;
        box-shadow: 0 .1rem .3rem rgba(#000, 0.12), 0 .1rem .2rem rgba(#000, 0.24);
        position: relative;
        .status {
          height: 1rem;
          padding: 0 .5rem;
          background-color: #2ed573;
          position: absolute;
          right: .1rem;
          bottom: .1rem;
          border-radius: 1rem;
          border: .2rem solid #fff;
          font-size: 0;
          font-weight: 700;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 100ms;
          text-transform: uppercase;
          &.free {
            background-color: #1e90ff; }
          &.active {
            background-color: #2ed573; }
          &.inactive {
            background-color: #ff4757; } } }
      .name {
        font-weight: 600;
        font-size: 1.4rem;
        color: $black;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 10rem; }
      .info {
        font-size: 1.1rem;
        color: $grey; }
      &:hover {
        box-shadow: 0 1rem 2rem rgba(#000, 0.19), 0 .6rem .6rem rgba(#000, 0.23);
        .photo {
          .status {
            font-size: .8rem; } } } } }
  .list-empty {
    height: calc(100vh - 70px - 3rem);
    background-image: url(assets/no-results.svg);
    background-position: center;
    background-repeat: no-repeat; }
  &.is-loading {
    .navbar {
      &:before {
        opacity: 1; } }
    &:after {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; } } }

@keyframes loading {
  0% {
    left: -100%; }
  100% {
    left: 100%; } }
